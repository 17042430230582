import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

import { useAppState } from '../state/appState';

// Components
import { PageMeta } from '../components/pageMeta';
import BlockZone from '~blockZone';
import {
  StandardPageBanner,
  LatestArticles,
} from '../components/blockZone/blocks';

const ArticleTemplate = ({ data: { page } }) => {
  const { setGlobalTheme } = useAppState();

  const { title, date, summary, meta, pageBlocks } = page || {};

  useEffect(() => {
    setGlobalTheme('white');
  }, [setGlobalTheme]);

  return (
    <>
      <StandardPageBanner date={date} caption={summary} title={title} />
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
      <LatestArticles />
    </>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query standardArticleQuery($slug: String!) {
    page: sanityArticle(slug: { current: { eq: $slug } }) {
      title
      summary
      date(formatString: "DD MMM YYYY")
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
