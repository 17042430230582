import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const BlockZone = ({ blocks }) => {
  return (
    <>
      {blocks.map((item) => {
        const { _type } = item;
        const Module = loadable(() => import(`./blocks/${_type}`));
        return <Module {...item} />;
      })}
    </>
  );
};

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default BlockZone;
